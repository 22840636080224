module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Decentraland","short_name":"Decentraland","start_url":"/","background_color":"#18141a","theme_color":"#18141a","display":"minimal-ui","icon":"node_modules/decentraland-gatsby/static/decentraland.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b57be10317666edc399e2bdf863f26bf"},
    },{
      plugin: require('../node_modules/decentraland-gatsby/dist/plugins/intl/gatsby-browser.js'),
      options: {"plugins":[],"paths":["/opt/buildhome/repo/src/intl"],"locales":["en"],"defaultLocale":"en"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
